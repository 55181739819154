import Popup from '@/components/popup.vue';
import { ITable } from '@/components/table_v2';
import Table from '@/components/table_v2/table.vue';
import store from '@/store';
import { IStateUser } from '@/store/modules/users';
import { IUsersTranslations } from '@/translations';
import sort from '@/utils/sort';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter, Mutation } from 'vuex-class';

import { table, userActionPopup } from '.';

@Component({
  name: 'Users',
  components: { Table, Popup }
})
class Users extends Vue {
  @Action('fetchUsers') public fetch!: (page: number, pageSize: number, search?: string) => void;

  @Mutation('selectUser') public selectUser!: (user: IStateUser) => void;

  @Getter('getUsers') public users!: IStateUser[];

  @Getter('getUsersTranslations') public translations!: IUsersTranslations;

  public table = {} as ITable<IStateUser>;

  public userActionPopup = userActionPopup;

  public created() {

    this.fetch(1, 20);
    this.table = table(this.translations);
    this.table.onSelectedItem = this.onUserSelected;

    store.subscribe((mutation: MutationPayload) => {

      if (mutation.type === 'setLanguage') {

        this.table = { ...this.table, ...table(this.translations) };

      }

      if (mutation.type !== 'selectUser') {

        const key = Object.keys(this.table.order) as Array<Extract<IStateUser, string>>;
        const field = key[0];
        const order = this.table.order[key[0]];

        if (mutation.type !== 'setUsersLoading') {
          this.table = {
            ...this.table,
            data: sort(field, order, this.users)
          };
        }
      }
    });

  }
  public onUserSelected(user: IStateUser) {
    this.selectUser(user);
  }
  public triggerPopup({ message, type }: { message: string, type: string }) {
    this.userActionPopup = {
      ...this.userActionPopup,
      isActive: true,
      message,
      type,
    };
  }
}

export default Users;
